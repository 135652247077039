import React from 'react';

import { SvgContent } from '@dop/ui-primitives/media/Svg';

/**
 * --fill-red and --fill-blue can be used to overwrite colors
 */
export const IconFlagNL: SvgContent = ({ cropped: _, ...props }) => {
	const id = React.useId();
	return (
		<svg viewBox="0 0 64 64" width="64" height="64" {...props}>
			<defs>
				<circle id={`${id}-circle`} cx={32} cy={32} r={32} />
				<clipPath id={`${id}-clipPath`}>
					<use xlinkHref={`#${id}-circle`} />
				</clipPath>
			</defs>
			<use xlinkHref={`#${id}-circle`} fill="white" />
			<g clipPath={`url(#${id}-clipPath)`}>
				<path fill="var(--fill-red, #1E4785)" d="M80 64h-96V42h96z" />
				<path fill="white" d="M80 42h-96V22h96z" />
				<path fill="var(--fill-blue, #A91F32)" d="M80 22h-96V0h96z" />
			</g>
		</svg>
	);
};
