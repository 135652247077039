import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';
import React from 'react';
import { Property as CSSProperty } from 'csstype';

import { getJSIn } from '@dop/shared/helpers/immutableHelpers';
import { LinkType } from '@/globals/webApiTypes/common.types';
import { oranje } from '@dop/ui-primitives/color/colors';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { IconPrinsjesdagSuitcase } from '@dop/ui-icons/svg/prinsjesdagSuitcase';
import { ContentLinkInline } from '@dop/ui-composites/link/ContentLinkInline';
import { Rows } from '@dop/ui-primitives/layout/Rows';
import { Occupy } from '@dop/ui-primitives/layout/Occupy';

type PrinsjesdagReference = {
	topText: string;
	topLink: LinkType;
	bottomText: string;
	bottomLink: LinkType;
};

export const usePrinsjesdagReference = () => {
	const prinsjesdagReference = usePageDataSelector(
		getJSIn(['prinsjesdagReference'])
	) as PrinsjesdagReference;

	return {
		hasPrinsjesdagReference: prinsjesdagReference != null,
		prinsjesdagReference,
	};
};

type Props = {
	$gridArea?: CSSProperty.GridArea;
};

export const PrinsjesdagLink = ({
	$gridArea,
}: Props): React.ReactElement | null => {
	const { prinsjesdagReference } = usePrinsjesdagReference();

	if (prinsjesdagReference == null) return null;

	const { bottomText, bottomLink } = prinsjesdagReference;

	return (
		<Box
			$gridArea={$gridArea}
			$backgroundColor={oranje[160]}
			$padding={1.5}
			$inlineSize="fit-content"
		>
			<Shelf $gap={1} $alignItems="start">
				<Occupy $blockSize={1}>
					<Svg
						as={IconPrinsjesdagSuitcase}
						$blockSize={2.3}
						$inlineSize={'auto'}
					/>
				</Occupy>

				<Rows $gap={[1, 2]}>
					<TextBlock $fontWeight={'bold'}>{bottomText}</TextBlock>
					<TextBlock $capSize={[1, 1.8]}>
						<ContentLinkInline
							href={bottomLink.href}
							linkType="prinsjesdag-bottom-link"
						>
							{bottomLink.text}
						</ContentLinkInline>
					</TextBlock>
				</Rows>
			</Shelf>
		</Box>
	);
};
