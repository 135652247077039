import React from 'react';

import { SvgContent } from '@dop/ui-primitives/media/Svg';

/**
 * --fill-red and --fill-blue can be used to overwrite colors
 */
export const IconFlagEN: SvgContent = ({ cropped: _, ...props }) => {
	const id = React.useId();
	return (
		<svg viewBox="0 0 64 64" width="64" height="64" {...props}>
			<defs>
				<circle id={`${id}-circle`} cx={32} cy={32} r={32} />
				<clipPath id={`${id}-clipPath`}>
					<use xlinkHref={`#${id}-circle`} />
				</clipPath>
			</defs>
			<g>
				<use xlinkHref={`#${id}-circle`} fill="white" />
				<g clipPath={`url(#${id}-clipPath)`}>
					<path fill="var(--fill-red, #A91F32)" d="M28 0h8v64h-8z" />
					<path fill="var(--fill-red, #A91F32)" d="M80 28v8h-96v-8z" />
					<path
						fill="var(--fill-blue, #1E4785)"
						d="M40.106 0v21.9L72.126 0zM24.125 0v21.9L-7.894 0zM40.106 63.9V42l32.02 21.9zM24.125 63.9V42L-7.894 63.9zM-15.894 40v20l29.242-20zM80.348 40v20L51.106 40zM-15.894 24V4l29.242 20zM80.348 24V4L51.106 24z"
					/>
					<path
						fill="var(--fill-red, #A91F32)"
						d="M-14.797-.025-15.894 0v2.3L17.171 24h3.71zM45.105 24h-3.894L76.17-.025h3.71zM-12.39 64l-3.61-.1L19.398 40l3.483-.025zM79.385 63.9h.963v-1.758L47.483 40h-3.667z"
					/>
				</g>
			</g>
		</svg>
	);
};
