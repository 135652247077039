import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconClock: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		focusable="false"
		{...(cropped
			? { viewBox: '6 6 51 51', width: '51', height: '51' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		{...props}
	>
		<g fillRule="evenodd">
			<rect width="64" height="64" style={{ fill: 'none' }}></rect>
			<path
				d="M34,16H29V33.37l12.7,7.76,2.61-4.27L34,30.57ZM31.5,6A25.5,25.5,0,1,0,57,31.5,25.53,25.53,0,0,0,31.5,6ZM46.13,46.13A20.69,20.69,0,1,1,52.2,31.5,20.56,20.56,0,0,1,46.13,46.13Z"
				transform="translate(0 0)"
			></path>
		</g>
	</svg>
);
