import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';
import React from 'react';

import { getIn } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { getJSIn } from '@dop/shared/helpers/immutableHelpers';
import {
	ContentOwnerItem,
	ContentOwners,
} from '@/globals/webApiTypes/contentOwners.types';

export const useContentOwners = () => {
	const text: string = usePageDataSelector(
		getIn(['contentOwners', 'infoFromText'])
	);
	const contentOwnersList: ContentOwners['list'] = usePageDataSelector(
		getJSIn(['contentOwners', 'list'])
	);

	const contentOwnersNameList = React.useMemo(() => {
		if (contentOwnersList == null) return [];

		return contentOwnersList.reduce(
			(acc: Array<string>, value: ContentOwnerItem) => [...acc, value.name],
			[]
		);
	}, [contentOwnersList]);
	const contentOwnersText = `${text} ${contentOwnersNameList.join(' | ')}`;

	return {
		hasContentOwners: contentOwnersList != null && contentOwnersList.length > 0,
		text,
		list: contentOwnersList,
		contentOwnersText,
	};
};
