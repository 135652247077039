import { callToAction } from '@/uiComposites/interactive/callToAction';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { Site } from '@/globals/webApiTypes/site.types';
import { useFormStatus } from 'react-dom';
import { addTestSelector } from '@dop/shared/helpers/testSelector';

export const SubmitButton = ({
	text,
	loadingText,
	errorText,
	isRejected,
}: {
	text: Site['pageFeedback']['submitButtonText'];
	loadingText: Site['pageFeedback']['submitMessages']['loadingMessage'];
	errorText: Site['pageFeedback']['submitMessages']['errorMessage'];
	isRejected: boolean;
}) => {
	const { pending } = useFormStatus();

	return (
		<button
			type="submit"
			className={classes(
				zIndex({ zIndex: '1' }),
				callToAction({
					box:
						isRejected && !pending
							? {
									backgroundColor: 'positiveRed',
							  }
							: undefined,
				})
			)}
			{...addTestSelector('uiFeedbackSubmitButton')}
		>
			<span role="alert">
				{pending ? loadingText : isRejected ? errorText : text}
			</span>
		</button>
	);
};
