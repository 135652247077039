import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';
import React from 'react';
import { Property as CSSProperty } from 'csstype';

import { getIn } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { AnyImmutableJsMap } from '@dop/shared/types/immutableJs';
import { getJSIn, isFilled } from '@dop/shared/helpers/immutableHelpers';
import { config } from 'moduleAlias/config';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { IconClock } from '@dop/ui-icons/svg/clock';
import { IconFlagEN } from '@dop/ui-icons/svg/flagEN';
import { IconFlagNL } from '@dop/ui-icons/svg/flagNL';
import { IconGlasses } from '@dop/ui-icons/svg/glasses';
import { dateMediumFormatter } from '@dop/shared/helpers/dateTimeFormatters';
import { translate } from '@dop/shared/translate/translate';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { TextInline } from '@dop/ui-primitives/typography/TextInline';
import { neutral } from '@dop/ui-primitives/color/colors';
import { WithLinkTypeIcon } from '@dop/ui-composites/link/WithLinkTypeIcon';
import { LinkBlock } from '@dop/ui-composites/link/LinkBlock';
import { Occupy } from '@dop/ui-primitives/layout/Occupy';
import { TextStyle } from '@dop/ui-primitives/typography/TextStyle';
import { Rows } from '@dop/ui-primitives/layout/Rows';
import { ContentOwnerItem } from '@/globals/webApiTypes/contentOwners.types';
import { Image } from '@dop/ui-composites/media/Image';
import { ScrollInline } from '@dop/ui-composites/positioning/ScrollInline';
import { mqWidth640px } from '@dop/ui-primitives/base/mediaQueryDefinitions';

import { useContentOwners } from '../contentOwners/useContentOwners';
import { PrinsjesdagTopLink } from './PrinsjesdagTopLink';
import { usePrinsjesdagReference } from '../prinsjesdagLink/PrinsjesdagLink';

const SHOW_READTIME_FROM = 0; // words, e.g. 300 will hide it for articles that < or ± 1 minute readtime;
const WORDS_PER_MINUTE = 250; // Insights say that 250 words p/min is average...

const useReadTime = () => {
	const wordCount = usePageDataSelector(getIn(['wordCount']));

	const readTime =
		wordCount != null &&
		config.project === 'op' &&
		wordCount >= SHOW_READTIME_FROM
			? Math.round(wordCount / WORDS_PER_MINUTE)
			: undefined;

	return {
		wordCount,
		readTime,
	};
};

const ReadTime = ({
	readTime,
	wordCount,
}: {
	readTime?: number;
	wordCount?: number;
}): React.ReactElement | null => {
	if (readTime == null) return null;

	return (
		<Shelf $gap={0.5}>
			<Occupy $blockSize={'var(--cap-height)'}>
				<Svg
					as={IconGlasses}
					aria-label={translate('reading duration')}
					$blockSize={1}
					$inlineSize={'auto'}
					$fill={['text', 'muted']}
				/>
			</Occupy>
			<TextBlock>
				{wordCount != null && (
					<TextInline
						title={`${wordCount} ${translate('words', {
							transform: 'lowercase',
						})}`}
						{...addTestSelector('uiReadTime')}
					>
						{wordCount < WORDS_PER_MINUTE ? '< 1' : `± ${readTime}`}
					</TextInline>
				)}{' '}
				{translate('min to read', { transform: 'lowercase' })}
			</TextBlock>
		</Shelf>
	);
};

const useLastChecked = () => {
	const lastChecked: string = usePageDataSelector(
		getIn(['metadata', 'lastChecked'])
	);
	const sdg: AnyImmutableJsMap = usePageDataSelector(getIn(['sdg']));

	if (!isFilled(sdg)) {
		return undefined;
	}

	return lastChecked;
};

const LastChecked = ({
	lastChecked,
}: {
	lastChecked?: string;
}): React.ReactElement | null => {
	if (lastChecked == null) return null;

	return (
		<Shelf $gap={0.5}>
			<Occupy $blockSize={'var(--cap-height)'}>
				<Svg
					as={IconClock}
					aria-hidden="true"
					$blockSize={1}
					$inlineSize={'auto'}
					$fill={['text', 'muted']}
				/>
			</Occupy>
			<TextBlock>
				{/* kind of weird translation: Dutch says 'laatst gecontroleerd op' */}
				{translate('last updated on')}{' '}
				<time dateTime={lastChecked}>
					{dateMediumFormatter.format(new Date(lastChecked))}
				</time>
			</TextBlock>
		</Shelf>
	);
};

type SupportedLanguages = 'en' | 'nl';

const useChangeLanguageIcon = (lang: SupportedLanguages) => {
	switch (lang) {
		case 'nl':
			return IconFlagNL;
		case 'en':
			return IconFlagEN;
		default:
			return null;
	}
};
type ChangeLanguageProps = {
	href: string;
	text: string;
	lang: SupportedLanguages;
};
export const useChangeLanguageProps = () => {
	const changeLanguageProps = usePageDataSelector(
		getJSIn(['alternativeLanguagePageLink'])
	) as ChangeLanguageProps | undefined;

	return changeLanguageProps;
};

const ChangeLanguageLink = ({ href, text, lang }: ChangeLanguageProps) => {
	const ChangeLanguageIcon = useChangeLanguageIcon(lang);

	return (
		<LinkBlock
			href={href}
			linkType="changeLanguageLink"
			{...addTestSelector('uiChangeLanguageLink')}
		>
			<Shelf $gap={0.5}>
				{ChangeLanguageIcon && (
					<Occupy $blockSize={'var(--cap-height)'}>
						<Svg
							as={ChangeLanguageIcon}
							$blockSize={1.2}
							$inlineSize={'auto'}
							$fill={{
								red: '#858585',
								blue: '#676767',
							}}
							$hover={{
								$fill: {
									red: 'unset',
									blue: 'unset',
								},
							}}
							$active={{
								$fill: {
									red: 'unset',
									blue: 'unset',
								},
							}}
						/>
					</Occupy>
				)}
				<TextBlock lang={lang} $textDecorationLine="underline">
					<WithLinkTypeIcon href={href}>{text}</WithLinkTypeIcon>
				</TextBlock>
			</Shelf>
		</LinkBlock>
	);
};

type ContentOwnersProps = {
	contentOwnersText?: string;
	contentOwnersList?: Array<ContentOwnerItem>;
	$justifyContentOwners?: Extract<
		CSSProperty.JustifyItems,
		'stretch' | 'center'
	>;
};

const ContentOwner = ({ name, image }: ContentOwnerItem) => (
	<Shelf $gap={0.5} $alignItems="center">
		<Box
			$backgroundColor={neutral[60]}
			$padding={0.1}
			$blockSize={1.5}
			$inlineSize={1.5}
		>
			<Image
				loading="eager"
				src={image.src}
				alt={image.alt}
				$blockSize={'100%'}
				$inlineSize={'100%'}
			/>
		</Box>
		<TextBlock>{name}</TextBlock>
	</Shelf>
);

const ContentOwners = ({
	contentOwnersText,
	contentOwnersList,
	$justifyContentOwners = 'stretch',
}: ContentOwnersProps) => {
	return (
		<Stack $gap={1} $justifyItems={$justifyContentOwners}>
			<TextBlock $inlineSize="max-content">{contentOwnersText}</TextBlock>
			{contentOwnersList != null && contentOwnersList.length > 0 && (
				<ScrollInline
					$blockSize="fit-content"
					$mq={{
						[mqWidth640px.min]: {
							$overflowX: 'hidden',
						},
					}}
				>
					<Box $paddingBlock={0.1}>
						<Rows $gap={1}>
							{contentOwnersList.map((contentOwnerItem, index) => {
								return <ContentOwner key={index} {...contentOwnerItem} />;
							})}
						</Rows>
					</Box>
				</ScrollInline>
			)}
		</Stack>
	);
};

type MetadataProps = {
	readTime?: number;
	wordCount?: number;
	lastChecked?: string;
	changeLanguageProps?: ChangeLanguageProps;
} & ContentOwnersProps;
// Generic metadata block about a page that accepts props
export const MetaData = ({
	readTime,
	wordCount,
	lastChecked,
	changeLanguageProps,
	contentOwnersText,
	contentOwnersList,
	$justifyContentOwners,
}: MetadataProps): React.ReactElement | null => {
	const { hasPrinsjesdagReference } = usePrinsjesdagReference();

	return (
		<TextStyle
			$capSize={[0.75, 1.8]}
			$color={['text', 'muted']}
			$fontStyle="italic"
			// To prevent Google Search from showing this in their search result snippet
			data-nosnippet
		>
			<Stack $gap={1.25}>
				<Box
					$borderBlockEnd={['2px', 'solid', neutral[110]]}
					$paddingBlock={[0, 1.25]}
					{...addTestSelector('uiContentOwnersHeader')}
				>
					<ContentOwners
						contentOwnersText={contentOwnersText}
						contentOwnersList={contentOwnersList}
						$justifyContentOwners={$justifyContentOwners}
						{...addTestSelector('uiContentOwnersHeader')}
					/>
				</Box>
				{(readTime != null ||
					lastChecked != null ||
					changeLanguageProps != null ||
					hasPrinsjesdagReference) && (
					<Box
						$borderBlockEnd={['2px', 'solid', neutral[110]]}
						$paddingBlock={[0, 1.25]}
					>
						<Rows $alignItems={'center'} $gap={[1.25, 2]} data-nosnippet>
							{lastChecked != null && <LastChecked lastChecked={lastChecked} />}
							{readTime != null && (
								<ReadTime readTime={readTime} wordCount={wordCount} />
							)}
							{changeLanguageProps != null && (
								<ChangeLanguageLink {...changeLanguageProps} />
							)}
							{hasPrinsjesdagReference != null && <PrinsjesdagTopLink />}
						</Rows>
					</Box>
				)}
			</Stack>
		</TextStyle>
	);
};

// Metadata block specifically for articles
export const ArticleMetaData = ({
	$justifyContentOwners,
}: Pick<
	ContentOwnersProps,
	'$justifyContentOwners'
>): React.ReactElement | null => {
	const { readTime, wordCount } = useReadTime();
	const lastChecked = useLastChecked();
	const { hasContentOwners, text, list } = useContentOwners();
	const changeLanguageProps = useChangeLanguageProps();

	// No content-owners? Then also don't show anything else
	if (!hasContentOwners) return null;

	return (
		<MetaData
			readTime={readTime}
			wordCount={wordCount}
			lastChecked={lastChecked}
			changeLanguageProps={changeLanguageProps}
			contentOwnersText={`${text}:`}
			contentOwnersList={list}
			$justifyContentOwners={$justifyContentOwners}
		/>
	);
};
