import styled, { css } from 'styled-components';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import { baseStyle } from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	textBlockInteractiveStyleMap,
	TextBlockInteractiveStyleProps,
	textBlockStaticStyleMap,
	TextBlockStaticStyleProps,
} from './TextBlock';
import { getCapSizeContextCSS } from './typographyStyleFunctions';

export type TextStyleStaticStyleProps = TextBlockStaticStyleProps;

export type TextStyleInteractiveStyleProps = TextBlockInteractiveStyleProps;

export type TextStyleComponentStyleProps = ComponentStyleProps<
	TextStyleStaticStyleProps,
	TextStyleInteractiveStyleProps
>;

export const textStyleStaticStyleMap: StyleMap<
	TextStyleStaticStyleProps & TextStyleInteractiveStyleProps
> = {
	...textBlockStaticStyleMap,
	/**
	 * This does not set the font-size related properties directly.
	 * Instead this creates CSS variables for <TextBlock> to use.
	 */
	$capSize: getCapSizeContextCSS,
};

export const textStyleInteractiveStyleMap: StyleMap<TextStyleInteractiveStyleProps> =
	{
		...textBlockInteractiveStyleMap,
	};

const textStyleStyleMap: ComponentStyleMap<
	TextStyleStaticStyleProps,
	TextStyleInteractiveStyleProps
> = {
	normal: textStyleStaticStyleMap,
	hover: textStyleInteractiveStyleMap,
	active: textStyleInteractiveStyleMap,
	focus: textStyleInteractiveStyleMap,
	checked: textStyleInteractiveStyleMap,
	disabled: textStyleInteractiveStyleMap,
};

/**
 * TextStyle is used to style multiple descending `<TextBlock>` elements.
 *
 * Note: the text block itself should still be wrapped inside a `<TextBlock>` element.
 * because the `<TextBlock>` element corrects the spacing before and after the text
 * using `CapSize.js`.
 *
 * Responsibilities:
 *
 * 1. Styling of text for mulptiple text blocks
 * 2. Setting CSS variables for `color` and `font-size`/`line-height`
 */
export const TextStyle = styled.span<TextStyleComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: block;
			${getCSSFromStyleProps(textStyleStyleMap, props)};
		`;
	}};
`;

export type TextStyleProps = StyledComponentPropsWithoutRef<typeof TextStyle>;
