import React from 'react';

import { LinkBlock } from '@dop/ui-composites/link/LinkBlock';
import { IconPrinsjesdagSuitcase } from '@dop/ui-icons/svg/prinsjesdagSuitcase';
import { mqWidth640px } from '@dop/ui-primitives/base/mediaQueryDefinitions';
import { Occupy } from '@dop/ui-primitives/layout/Occupy';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';

import { usePrinsjesdagReference } from '../prinsjesdagLink/PrinsjesdagLink';

/**
 * In case there is a Prinsjesdag-reference from the Webapi, the article meta displays a link back to the Prinjesdag-page
 */
export const PrinsjesdagTopLink = (): React.ReactElement | null => {
	const { prinsjesdagReference } = usePrinsjesdagReference();

	if (prinsjesdagReference == null) return null;

	const { topText, topLink } = prinsjesdagReference;

	return (
		<Occupy
			$flex="1 0 100%"
			$mq={{ [mqWidth640px.min]: { $blockSize: 1, $flex: '1 0 auto' } }}
		>
			<Shelf
				$justifySelf="start"
				$gap={1}
				$alignItems="center"
				$mq={{ [mqWidth640px.min]: { $justifySelf: 'end' } }}
			>
				<Svg
					as={IconPrinsjesdagSuitcase}
					$blockSize={2.3}
					$inlineSize={'auto'}
				/>
				<Stack $gap={0.75}>
					<TextBlock $fontWeight="bold">{topText}</TextBlock>
					<LinkBlock href={topLink.href} linkType="prinsjesdag-top-link">
						<TextBlock $textDecorationLine="underline">
							{topLink.text}
						</TextBlock>
					</LinkBlock>
				</Stack>
			</Shelf>
		</Occupy>
	);
};
