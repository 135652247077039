import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconGlasses: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		focusable="false"
		{...(cropped
			? { viewBox: '2.04 10.05 59.99 41.91', width: '59.99', height: '41.91' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		{...props}
	>
		<g fillRule="evenodd">
			<rect width="64" height="64" style={{ fill: 'none' }}></rect>
			<path d="M62,33.52a2.54,2.54,0,0,0-.44-1.25h0c-.94-1.59-9.23-15.6-11.2-18.11-2.84-3.61-6.64-4.11-10.32-4.11v5c3.92,0,5.27.77,6.39,2.2,1,1.26,4.44,6.88,7.63,12.21a32.36,32.36,0,0,0-16.83,1.91A10.39,10.39,0,0,0,32,30.12a10.39,10.39,0,0,0-5.18,1.25A32.39,32.39,0,0,0,10,29.46c3.2-5.33,6.66-10.95,7.64-12.21,1.12-1.43,2.47-2.2,6.39-2.2v-5c-3.68,0-7.48.5-10.32,4.11-2,2.51-10.26,16.52-11.2,18.11h0a2.5,2.5,0,0,0-.43,1.24c-.36,6,1.81,17.59,12.56,18.41,6.31.48,10.58-3.32,13-9.15a30.82,30.82,0,0,0,1.94-7.33,6.62,6.62,0,0,1,4.9,0,30.82,30.82,0,0,0,1.94,7.33c2.43,5.85,6.71,9.63,13,9.15C60.15,51.11,62.32,39.53,62,33.52ZM15,47c-6.84-.52-7.84-8.32-7.95-11.67A23,23,0,0,1,14.79,34a28.61,28.61,0,0,1,9.66,1.75C23.46,41.48,20.76,47.43,15,47Zm34,0c-5.76.45-8.45-5.55-9.45-11.21A28.86,28.86,0,0,1,49.24,34,22.9,22.9,0,0,1,57,35.31C56.86,38.66,55.86,46.46,49,47Z"></path>
		</g>
	</svg>
);
