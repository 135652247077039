import React from 'react';
import { List } from 'immutable';

import {
	TextBlock,
	TextBlockProps,
} from '@dop/ui-primitives/typography/TextBlock';
import { Markup } from '@dop/shared/components/markup/Markup';
import { MarkupNode } from '@dop/shared/components/markup/Markup.types';
import { MarkupResolvers } from '@dop/shared/components/markup/markupResolvers';

import { marginTrimCSS } from './MarginTrim';

export type MarkupTextBlockProps = {
	html?: Array<MarkupNode> | List<MarkupNode>;
	markupResolvers?: MarkupResolvers;
} & TextBlockProps;

/**
 * This is a TextBlock that wraps around a markup-div. This is a temporary component until the moment we render markup-paragraphs/headings/etc inside TextBlocks.
 * This component will try to strip off any bottom-margins (from paragraphs, who have `margin-bottom: 1rem`), and tries to reapply any capsize-correction on the
 * block-margins.
 *
 * Be aware that this capsize-correction is based on a regular `<p>` paragraph; when the contents of a `MarkupTextBlock` starts or ends with a different element
 * (e.g. a heading like `<h1>`), this correction will not be accurate.
 */
export const MarkupTextBlock = ({
	html,
	markupResolvers,
	...textBlockProps
}: MarkupTextBlockProps) => {
	return (
		<TextBlock {...textBlockProps}>
			<Markup
				html={html}
				markupResolvers={markupResolvers}
				css={`
					/* allow regular text to be basically styled by parent TextBlock, etc */
					font-size: inherit;
					line-height: inherit;
					color: inherit;
					font-weight: inherit;
					text-decoration: inherit;

					${marginTrimCSS};
				`}
			/>
		</TextBlock>
	);
};
