import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { indentSize } from '@/uiPrimitives/layout/indentSize.definitions';
import { textBlock, textStyle } from '@/uiPrimitives/typography/text';

export const callToActionBox = (props: {
	box?: Parameters<typeof box>[0];
	textStyle?: Parameters<typeof textStyle>[0];
}) =>
	classes(
		box({
			inlineSize: 'fit-content',
			paddingBlock: '3 | h1',
			paddingInline: indentSize,
			backgroundColor: 'positiveBlue',
			...props.box,
		}),
		textStyle({
			fontWeight: 'bold',
			capLineHeight: 'tight',
			textDecorationLine: 'none',
			textAlign: 'center',
			...props.textStyle,
		})
	);

export const callToAction = (props: {
	box?: Parameters<typeof box>[0];
	textStyle?: Parameters<typeof textStyle>[0];
}) => classes(callToActionBox(props), textBlock({}));

export const submitButtonBox = (props: {
	box?: Parameters<typeof box>[0];
	textStyle?: Parameters<typeof textStyle>[0];
}) =>
	classes(
		box({
			inlineSize: 'fit-content',
			paddingBlock: '3 | h1',
			paddingInline: indentSize,
			backgroundColor: 'positive',
			...props.box,
		}),
		textStyle({
			fontWeight: 'bold',
			capLineHeight: 'tight',
			textDecorationLine: 'none',
			textAlign: 'center',
			...props.textStyle,
		})
	);

export const submitButton = (props: {
	box?: Parameters<typeof box>[0];
	textStyle?: Parameters<typeof textStyle>[0];
}) => classes(submitButtonBox(props), textBlock({}));

export const secondaryButtonBox = (props: {
	box?: Parameters<typeof box>[0];
	textStyle?: Parameters<typeof textStyle>[0];
}) =>
	classes(
		box({
			inlineSize: 'fit-content',
			paddingBlock: '3 | h1',
			paddingInline: indentSize,
			backgroundColor: 'negative:hoverBlue',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: 'positiveBlue',
			...props.box,
		}),
		textStyle({
			fontWeight: 'bold',
			capLineHeight: 'tight',
			textDecorationLine: 'none',
			color: 'positiveBlue:noVisited',
			textAlign: 'center',
			...props.textStyle,
		})
	);

export const secondaryButton = (props: {
	box?: Parameters<typeof box>[0];
	textStyle?: Parameters<typeof textStyle>[0];
}) => classes(secondaryButtonBox(props), textBlock({}));
